.floating-login-button {
  position: fixed; /* Float above other content */
  bottom: 20px; /* Distance from bottom */
  right: 20px; /* Distance from right */
  background-color: var(--highlight-color); /* Use theme highlight color */
  color: white; /* Text color */
  border: none;
  border-radius: 50px; /* Make it pill-shaped */
  padding: 10px 20px; /* Adjust padding */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  z-index: 1000; /* Ensure it's above most other elements */
  display: flex; /* Align icon and text */
  align-items: center;
  gap: 8px; /* Space between icon and text */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.floating-login-button:hover {
  background-color: var(--secondary-color); /* Darken on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

.floating-login-button .login-icon {
  font-size: 1.2rem; /* Adjust icon size if needed */
} 