.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dim-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.welcome-modal {
  max-width: 400px;
  width: 100%;
  background-color: var(--cardBackground-color);
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 5px;
  border-color: var(pink);
  position: relative;
  border: 1px solid var(--event-venue-color);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--text-color1);
}

.welcome-modal h2 {
  color: var(--text-color1);
  text-align: center;
  margin-bottom: 0;
}

.welcome-modal h1 {
  margin: 0;
  text-align: center;
  line-height: 1;
}

.welcome-modal h1 img {
  display: block;
  margin: 0 auto;
}

.modal-content {
  color: var(--text-color1);
}

.welcome-section {
  margin-bottom: 20px;
}

.welcome-section h3 {
  color: var(--text-color1);
  margin-bottom: 0px;
  text-align: center;
}

.welcome-section ul {
  list-style-type: none;
  padding-left: 0;
}

.welcome-section li {
  margin: 8px 0;
  padding-left: 20px;
  position: relative;
}

.welcome-section li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--highlight-color);
}

.cta-button {
  width: 100%;
  padding: 12px;
  background: var(--highlight-color);
  color: var(--text-color2);
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: 20px;
}

.cta-button:hover {
  background: var(--secondary-color);
}

.welcome-logo {
  height: 2.5em;
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .welcome-logo {
    height: 2em;
  }

  .welcome-modal {
    width: 90%;  /* Make modal slightly narrower on mobile */
    padding: 15px;  /* Reduce padding on mobile */
  }
} 